import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CustomTable from '@src/components/CustomTable';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  DEFAULT_APP_ICON,
  START_TIME_DEFAULT,
  REPORT_END_TIME_DEFAULT,
  INIT_PAGINATION,
  REPORT_GROUP_OPTION,
} from '@src/constants';
import { COLOR } from '@src/styles/color';
import useSearchParams from '@src/hooks/useSearchParams';
import useFormatNumber from '@src/hooks/useFormatNumber';
import apis from '@src/apis';
import { REPORT_CHARTS } from '@src/constants/chart';
import moment from 'moment';

const StatsValue = ({ value, changed, onClickDetail, sx = {} }) => {
  const { formatNumber, toLocaleString } = useFormatNumber();
  const color = changed > 0 ? COLOR.success.base : 'error';

  return (
    <Box display="flex" justifyContent="flex-end" sx={sx}>
      <Box display="flex" flexDirection="column" width="fit-content">
        <Tooltip title={toLocaleString(value)} placement="top">
          <Typography
            variant="h4b"
            textAlign="end"
            sx={{ '&:hover': { color: COLOR.info.base } }}
            onClick={(e) => {
              e.stopPropagation();
              onClickDetail();
            }}
          >
            {formatNumber(value)}
          </Typography>
        </Tooltip>
        <Tooltip
          title={`${changed > 0 ? '+' : ''}${toLocaleString(changed)}%`}
          placement="top"
        >
          <Typography
            variant="body2"
            color={changed === 0 ? COLOR.blueGray[64] : color}
            textAlign="end"
          >{`${changed > 0 ? '+' : ''}${formatNumber(changed)}%`}</Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

const ReportTable = ({ maxHeight = 'calc(100vh - 165px)' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { params, addParams } = useSearchParams();
  const {
    startTime = START_TIME_DEFAULT,
    endTime = REPORT_END_TIME_DEFAULT,
    appName,
    organizationId,
    page,
  } = params;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState({
    apps: [],
    pagination: { ...INIT_PAGINATION, page },
  });

  const dateBeforeEndTime = moment(endTime)
    .subtract(1, 'day')
    .endOf('day')
    .subtract(7, 'hours')
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  const isFilterToCurrentDate = useMemo(
    () =>
      moment(endTime)
        .startOf('day')
        .isSame(moment(REPORT_END_TIME_DEFAULT).startOf('day')),
    [endTime],
  );

  const goToDetail = (appId, report) =>
    addParams({
      appId,
      startTime: startTime || START_TIME_DEFAULT,
      endTime: endTime || REPORT_END_TIME_DEFAULT,
      report,
      group: REPORT_GROUP_OPTION.DAY,
    });

  const heads = [
    {
      label: 'Tên ứng dụng',
      valueName: 'name',
      align: 'left',
    },
    {
      label: 'Tổ chức',
      valueName: 'organizationName',
      align: 'left',
    },
    {
      label: 'Doanh thu',
      valueName: 'revenue',
      align: 'right',
      width: '15%',
    },
    {
      label: 'Số thuê bao tương tác',
      valueName: 'user',
      align: 'right',
      width: '15%',
    },
    {
      label: 'Sản lượng giao dịch',
      valueName: 'transaction',
      align: 'right',
      width: '15%',
    },
    {
      label: (
        <Box
          paddingRight="20px"
          display="inline-flex"
          alignItems="center"
          gap="4px"
        >
          Số thuê bao hoạt động
          {isFilterToCurrentDate && (
            <Tooltip
              title={t('dataOnlyQueryUntilDate', {
                date: moment(dateBeforeEndTime).format('DD/MM/YYYY'),
              })}
              arrow
              placement="top"
            >
              <InfoRoundedIcon fontSize="small" color="primary" />
            </Tooltip>
          )}
        </Box>
      ),
      valueName: 'userActive',
      align: 'right',
      width: '15%',
    },
  ];

  const fetchAppsReport = async () => {
    try {
      setIsLoading(true);
      const res = await apis.report.getAppReport({
        appName,
        organizationId,
        startTime,
        endTime,
        pageNum: page || 1,
      });
      if (res?.status)
        setData((prevData) => ({
          ...prevData,
          apps: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || 10,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (p) => addParams({ page: p });

  useEffect(() => {
    fetchAppsReport();
  }, [startTime, endTime, appName, organizationId, page]);

  const formatData =
    data.apps?.map((item) => ({
      name: (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar
            variant="rounded"
            src={
              item?.latestPublishedAppVersion?.appImageUrl || DEFAULT_APP_ICON
            }
            alt={item.name}
            sizes="small"
          />
          <Typography variant="h6b" className="nowrap">
            {item.name}
          </Typography>
        </Box>
      ),
      organizationName: (
        <Typography variant="h6b" className="nowrap">
          {item.organization.name}
        </Typography>
      ),
      revenue: (
        <StatsValue
          value={item?.report?.revenue?.total}
          changed={item?.report?.revenue?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.REVENUE)}
        />
      ),
      user: (
        <StatsValue
          value={item?.report?.user?.total}
          changed={item?.report?.user?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.USER)}
        />
      ),

      transaction: (
        <StatsValue
          value={item?.report?.transaction?.total}
          changed={item?.report?.transaction?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.TRANSACTION)}
        />
      ),

      userActive: (
        <StatsValue
          sx={{ paddingRight: '20px' }}
          value={item?.report?.userActive?.total}
          changed={item?.report?.userActive?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.USER_ACTIVE)}
        />
      ),

      id: item.id,
    })) || [];

  return (
    <CustomTable
      items={formatData}
      heads={heads}
      onClickRow={(row) =>
        addParams({
          appId: row.id,
          startTime: startTime || START_TIME_DEFAULT,
          endTime: endTime || REPORT_END_TIME_DEFAULT,
        })
      }
      checkClassList={false}
      maxHeight={maxHeight}
      isStickyHeader
      pagination={data.pagination}
      onChangePagination={handleChangePage}
      loading={isLoading}
    />
  );
};

export default ReportTable;
