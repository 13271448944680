import moment from 'moment';

const LANGUAGE = { VN: 'vi', EN: 'en' };

const SERVICE = {
  ACCOUNT: 'ACCOUNT',
  API_MANAGEMENT: 'API_MANAGEMENT',
  IAM: 'IAM',
};

const TRANSACTION_STATUS = { SUCCESS: 'SUCCESS', FAILED: 'FAILED' };

const TRANSACTION_ACTION = {
  REGISTER: 'REGISTER',
  RENEW: 'RENEW',
  CANCEL: 'CANCEL',
  RENEW_ERROR: 'RENEW_ERROR',
  REGISTER_NOT_EM: 'REGISTER_NOT_EM',
};

const DATE_TIME_PICKER_TYPES = {
  DATE: 'DATE',
  TIME: 'TIME',
  DATE_TIME: 'DATE_TIME',
};

const ERROR_CODE_SYSTEM = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
};

const ERROR_CODE_ACCOUNT_SERVICE = {
  // users 1000 - 1999
  USER_EXISTS: 1000,
  EMAIL_OR_PASSWORD_WRONG: 1001,
  USER_NOT_FOUND: 1002,
  INVALID_USER: 1003,

  // group permissions 2000 - 2999
  GROUP_PERMISSION_EXISTS: 2000,
  GROUP_PERMISSION_NOT_FOUND: 2001,
  GROUP_PERMISSION_HAS_PERMISSIONS: 2002,

  // permissions 3000 - 3999
  PERMISSION_EXIST: 3000,
  PERMISSION_NOT_FOUND: 3001,

  // iam 4000 - 4199
  IAM_GET_ACCESS_TOKEN_ERROR: 4000,
  IAM_CREATE_ROLE_ERROR: 4001,
  IAM_DELETE_ROLE_ERROR: 4002,

  // role 4200 - 4399
  ROLE_EXIST: 4200,
  ROLE_NOT_FOUND: 4201,
  ROLE_ASSIGNED_FOR_USER: 4202,

  // organization 4300 - 4399
  ORGANIZATION_NOT_FOUND: 4300,
  ORGANIZATION_NAME_EXIST: 4301,
  OWNER_INACTIVE: 4302,
};
const ERROR_CODE_API_MANAGEMENT_SERVICE = {
  // package 4500 - 4599
  PACKAGE_EXIST: 4500,
  PACKAGE_NOT_FOUND: 4501,
  PACKAGES_NUMBER_NOT_MATCH: 4502,
  PACKAGES_DUPLICATE_RANK: 4503,
  PACKAGE_NAME_EXIST: 4504,
  PACKAGE_CODE_EXIST: 4505,

  // special group 4600 - 4699
  SPECIAL_GROUP_EXIST: 4600,
  SPECIAL_GROUP_NOT_FOUND: 4601,
  SPECIAL_GROUPS_DUPLICATE_RANK: 4603,
  GROUP_NUMBER_NOT_MATCH: 4604,
  RANK_EXCEED_LIMIT: 4605,
  SPECIAL_GROUPS_DUPLICATE: 4606,
  DUPLICATE_APP_IN_GROUP: 4607,
  DUPLICATE_APP_RANK: 4608,

  // page 4700 - 4799
  PAGE_NOT_FOUND: 4700,

  // banner 4800 - 4899
  BANNER_NOT_FOUND: 4800,

  // article group 4900 - 4999
  ARTICLE_GROUP_NOT_FOUND: 4900,

  // article 5000 - 5099
  ARTICLE_NOT_FOUND: 5000,
  DUPLICATE_ARTICLE_IN_GROUP: 5001,
  DUPLICATE_ARTICLE_RANK: 5002,
};

const PAGINATION_LIMIT = 10;

const PERMISSION_TYPE = {
  API: 'API',
  MENU: 'MENU',
};

const API_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const ALL = 'all';

const INIT_PAGINATION = {
  page: 1,
  limit: 10,
  total: 0,
  totalPages: 0,
};

const APP_VERSION_STATUS = {
  WAITING_REVIEW: 'WAITING_REVIEW',
  PUBLISHED: 'PUBLISHED',
  APPROVED: 'APPROVED',
  PUBLISH_SCHEDULED: 'PUBLISH_SCHEDULED',
  REJECTED: 'REJECTED',
  BANNED: 'BANNED',
  DRAFT: 'DRAFT',
};
const ARTICLE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

const DEFAULT_APP_ICON =
  'https://d29fhpw069ctt2.cloudfront.net/clipart/90673/preview/iOS_7_Icon_Template_preview_4ffa.png';

const VERSION_ACTION = {
  APPROVE: 'approve',
  REJECT: 'reject',
  BAN: 'ban',
  UN_BAN: 'unban',
};

const AGE_RATING = {
  FOUR: 4,
  NIGHT: 9,
  TWELVE: 12,
  SEVENTEEN: 17,
};

const PAYMENT_METHOD = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING',
};

const TIME_UNIT = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
};

const PACKAGE_DISPLAY_OPTION = {
  PRIORITIZED: 'PRIORITIZED',
  DEFAULT: 'DEFAULT',
};

const SPECIAL_GROUP_LOCATION = { CUSTOM: 'CUSTOM', CATEGORY: 'CATEGORY' };

const SPECIAL_GROUP_TYPE = { MANUAL: 'MANUAL', AUTO: 'AUTO' };

const DEFAULT_CATEGORY_IMAGE =
  'https://cdn-icons-png.flaticon.com/512/4562/4562566.png';

const SPECIAL_GROUP_LAYOUT = {
  LIST_FOCUS_LEFT: 'LIST_FOCUS_LEFT',
  LIST_FOCUS_RIGHT: 'LIST_FOCUS_RIGHT',
  SWIPER: 'SWIPER',
  GRID: 'GRID',
};

const ARTICLE_LAYOUT = {
  OVERVIEW: 'OVERVIEW',
  SWIPER: 'SWIPER',
  GRID: 'GRID',
};

const PAGES = {
  HOME: {
    key: 'HOME',
    name: 'Trang chủ',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  MOBIFONE_AI: {
    key: 'MOBIFONE_AI',
    name: 'Mobifone AI',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  RANKING: {
    key: 'RANKING',
    name: 'Bảng xếp hạng',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  VOICE_AI: {
    key: 'VOICE_AI',
    name: 'Giọng nói AI',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  TEXT_AI: {
    key: 'TEXT_AI',
    name: 'Văn bản AI',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  IMAGE_AI: {
    key: 'IMAGE_AI',
    name: 'Hình ảnh AI',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  VIDEO_AI: {
    key: 'VIDEO_AI',
    name: 'Video AI',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  CATEGORIES: {
    key: 'CATEGORIES',
    name: 'Danh mục',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  APPs: {
    key: 'APPS',
    name: 'Tất cả ứng dụng',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  SEEN: {
    key: 'APP_SEEN',
    name: 'Đã xem',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
  YOUTH_AI: {
    key: 'YOUTH_AI',
    name: 'Giới trẻ AI',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/4562/4562566.png',
  },
};

const PAGE_BLOCK_TYPE = {
  SPECIAL_GROUP: 'SPECIAL_GROUP',
  BANNER: 'BANNER',
  ARTICLE_GROUP: 'ARTICLE_GROUP',
};

const DEVICE_TYPE = {
  PHONE: 'PHONE',
  WEBSITE: 'WEBSITE',
};

const IMAGE_TYPE_FILE = [
  'png',
  'jpeg',
  'jpg',
  'gif',
  'svg',
  'webp',
  'PNG',
  'JPEG',
  'JPG',
  'SVG',
  'GIF',
  'WEBP',
];

const IMAGE_ACCEPT_FILE =
  '.jpeg,.png,.jpg,.gif,.svg,.webp,.PNG,.JPG,.JPEG,.SVG,.WEBP,.GIF';

const MAX_LENGTH_ARTICLE = {
  HEADER: 100,
  SAPO: 150,
};

const MAX_FILE_SIZE_MEGABYTE = {
  THREE_MB: 3,
};

const GROUP_NAME_CHARACTER_LIMIT = 40;
const GROUP_DESC_CHARACTER_LIMIT = 300;

const FILE_MAX_SIZE = 200;

const DATE_TIME_FORMAT = 'HH:mm:ss - DD/MM/YYYY';

const DEFAULT_HOTLINE = '9217';

const MAX_SEARCH_LENGTH = 50;

// Chứa các ký tự đặc biệt
/* eslint-disable no-useless-escape */
const REGEX_SPECIAL_CHARACTERS = /[\,\.\^\$\*\+\?\[\]\|\(\)\\]/;

const DEBOUNCE_AUTO_COMPLETE = 500;
const DEBOUNCE_SEARCH_TEXT = 300;

const REPORT_GROUP_OPTION = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
};

const CHART_TYPE = { STACKED_BAR: 'stackedBar', AREA: 'area' };

const START_TIME_DEFAULT = moment()
  .subtract(1, 'months')
  .startOf('day')
  .subtract(7, 'hours')
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const END_TIME_DEFAULT = moment()
  .endOf('day')
  .subtract(7, 'hours')
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const REPORT_END_TIME_DEFAULT = moment()
  .endOf('day')
  .subtract(7, 'hours')
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const EXCEL_CONTENT_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const DATA_FILTER_TIME = { NO_LIMIT: 'NO_LIMIT', CUSTOM: 'CUSTOM' };

const NUM_APPS = { NO_LIMIT: 'NO_LIMIT', CUSTOM: 'CUSTOM' };

export {
  LANGUAGE,
  SERVICE,
  ERROR_CODE_SYSTEM,
  ERROR_CODE_ACCOUNT_SERVICE,
  PAGINATION_LIMIT,
  PERMISSION_TYPE,
  API_METHOD,
  ALL,
  INIT_PAGINATION,
  APP_VERSION_STATUS,
  DEFAULT_APP_ICON,
  VERSION_ACTION,
  AGE_RATING,
  PAYMENT_METHOD,
  TIME_UNIT,
  PACKAGE_DISPLAY_OPTION,
  ERROR_CODE_API_MANAGEMENT_SERVICE,
  SPECIAL_GROUP_LOCATION,
  TRANSACTION_STATUS,
  TRANSACTION_ACTION,
  DATE_TIME_PICKER_TYPES,
  SPECIAL_GROUP_TYPE,
  DEFAULT_CATEGORY_IMAGE,
  SPECIAL_GROUP_LAYOUT,
  PAGES,
  PAGE_BLOCK_TYPE,
  DEVICE_TYPE,
  IMAGE_TYPE_FILE,
  IMAGE_ACCEPT_FILE,
  MAX_FILE_SIZE_MEGABYTE,
  ARTICLE_LAYOUT,
  GROUP_NAME_CHARACTER_LIMIT,
  GROUP_DESC_CHARACTER_LIMIT,
  FILE_MAX_SIZE,
  DATE_TIME_FORMAT,
  DEFAULT_HOTLINE,
  MAX_SEARCH_LENGTH,
  REGEX_SPECIAL_CHARACTERS,
  DEBOUNCE_AUTO_COMPLETE,
  DEBOUNCE_SEARCH_TEXT,
  REPORT_GROUP_OPTION,
  START_TIME_DEFAULT,
  END_TIME_DEFAULT,
  REPORT_END_TIME_DEFAULT,
  EXCEL_CONTENT_TYPE,
  CHART_TYPE,
  ARTICLE_STATUS,
  MAX_LENGTH_ARTICLE,
  DATA_FILTER_TIME,
  NUM_APPS,
};
